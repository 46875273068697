






























































































































































































import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import { Application, ApplicationService } from '../../../libs/application';
import {
  OAuth2PKCEService,
  OAuth2Service,
  OAuth2AuthorizationRequest,
} from '../../../libs/oauth2';
import { routeToErrorPage } from '../../../libs/util';
import {
  LocalDatabaseStrategy,
  GoogleOAuth2Strategy,
  WindowsLiveStrategy,
  GitHubStrategy,
} from '../../../libs/strategies';
import {
  GoogleOAuth2SocialButton,
  WindowsLiveSocialButton,
  GitHubSocialButton,
} from '../../../components/social-buttons';
import { isHTTPError } from '../../../libs/http';

export default Vue.extend({
  mixins: [validationMixin],
  data() {
    return {
      isMounted: false,
      isFormLoading: false,

      request: OAuth2Service.getAuthorizationRequestFromRoute(this.$route),
      application: null as Application | null,

      resource: this.$route.query.resource?.toString(),
      token: this.$route.query.token?.toString(),
      strategy: this.$route.query.strategy?.toString().toLowerCase(),

      strategies: {
        local_database: {
          isFormLoading: false,
          isEmailDisabled: this.$route.query.email?.toString().length > 0,
          form: {
            email: this.$route.query.email?.toString(),
            password: '',
            password_confirm: '',

            given_name: '',
            family_name: '',
          },
        },
      },
    };
  },
  components: {
    GoogleOAuth2SocialButton,
    WindowsLiveSocialButton,
    GitHubSocialButton,
  },
  validations: {
    strategies: {
      local_database: {
        form: {
          email: {
            required,
          },
          password: {
            required,
          },
          password_confirm: {
            required,
            sameAsPassword: sameAs('password'),
          },
        },
      },
    },
  },
  computed: {
    showOrRow(): boolean {
      if (!this.application || !this.application.connections) {
        return false;
      }

      if (this.isStrategyActive('local-database') === false) {
        return false;
      }

      return this.application.connections.some(
        (connection) => connection.strategy !== 'local-database',
      );
    },
  },

  async mounted() {
    if (!this.request.client_id) {
      routeToErrorPage();
      return;
    }

    try {
      this.$set(
        this,
        'application',
        await ApplicationService.getByClientID(this.request.client_id),
      );
    } catch (e) {
      routeToErrorPage();
      return;
    }

    // If resource and/or token is not found, go to error page
    if (!this.resource || !this.token) {
      routeToErrorPage();
      return;
    }

    // If strategy is not found or not active, go to error page
    if (!this.strategy || this.isStrategyActive(this.strategy) === false) {
      routeToErrorPage();
      return;
    }

    this.$set(this, 'isMounted', true);

    if (this.strategy !== 'signup' && this.strategy !== 'local-database') {
      this.onStrategy(this.strategy);
    }
  },

  methods: {
    getStrategy(
      strategy: Application['connections'][number]['strategy'],
    ): Application['connections'][number] | undefined {
      return (this.application as Application).connections.find(
        (connection) => connection.strategy === strategy,
      );
    },

    isStrategyActive(
      strategy: Application['connections'][number]['strategy'],
    ): boolean {
      if (!this.application) {
        return false;
      }

      // Custom strategy 'signup' can also be used
      if (strategy === 'signup') {
        return true;
      }

      return this.getStrategy(strategy) !== undefined;
    },

    /**
     * begin::Strategy(local-database)
     */
    async onSubmit() {
      if (this.isStrategyActive('local-database') === false) {
        return;
      }

      const strategy = this.getStrategy('local-database');
      this.$set(this.strategies.local_database, 'isFormLoading', true);
      try {
        await LocalDatabaseStrategy.link(
          this.request.client_id,
          strategy?.identifier as string,
          {
            token: this.token,
            resource: this.resource,
          },
          {
            email: this.strategies.local_database.form.email,
            password: this.strategies.local_database.form.password,

            given_name: this.strategies.local_database.form.given_name,
            family_name: this.strategies.local_database.form.family_name,
          },
        );

        // Perform authorization request
        const authorization = await OAuth2Service.authorize(
          this.request as OAuth2AuthorizationRequest,
        );

        // Redirect back to redirect URI
        OAuth2Service.redirectToURI(authorization, this.request?.redirect_uri);
      } catch (e) {
        if (isHTTPError(e)) {
          console.error(e.response);
        }

        this.$set(this.strategies.local_database, 'isFormLoading', false);
        return;
      }
    },

    /**
     * begin::Strategy(social)
     */
    onStrategy(strategy: string): void {
      if (this.isStrategyActive(strategy) === false) {
        throw new Error(`Strategy '${strategy}' unknown`);
      }

      const connection = this.getStrategy(strategy);
      if (!connection) {
        return;
      }

      // Save request in session
      OAuth2Service.setStrategySession(
        this.request.client_id,
        connection.strategy,
        this.request,
      );

      // Create & save PKCE challenge for callback
      const pkce = OAuth2PKCEService.createPKCEChallenge();
      OAuth2PKCEService.savePKCEChallengeForClientID(
        this.request.client_id,
        connection.strategy,
        pkce,
      );

      // Define state
      const state = OAuth2Service.createStrategyState({
        type: 'link',
        application_client_id: this.request.client_id,
        client_id: connection.client_id as string,
        strategy: connection.strategy,
        token: this.token,
        resource: this.resource,
      });

      switch (connection.strategy) {
        case 'google-oauth2': {
          const uri = GoogleOAuth2Strategy.getAuthorizeURL(
            connection.client_id as string,
            {
              state,
              scope: connection?.scope?.join(' '),
              prompt: 'select_account',

              code_challenge: pkce.code_challenge,
              code_challenge_method: 'S256',
            },
          );

          window.location.href = uri.href;
          break;
        }

        case 'windowslive': {
          const uri = WindowsLiveStrategy.getAuthorizeURL(
            connection.client_id as string,
            connection.tenant as string,
            {
              state,
              scope: connection?.scope?.join(' '),
              prompt: 'select_account',

              code_challenge: pkce.code_challenge,
              code_challenge_method: 'S256',
            },
          );

          window.location.href = uri.href;
          break;
        }

        case 'github': {
          const uri = GitHubStrategy.getAuthorizeURL(
            connection.client_id as string,
            {
              state,
              scope: connection?.scope?.join(' '),
              prompt: 'select_account',

              code_challenge: pkce.code_challenge,
              code_challenge_method: 'S256',
            },
          );

          window.location.href = uri.href;
          break;
        }
      }
    },
  },
});
