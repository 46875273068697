













import Vue from 'vue';
import { SOCIAL_BUTTON_DEFAULT_CSS } from './constants';

export default Vue.extend({
  data() {
    return {
      DEFAULT_CSS: SOCIAL_BUTTON_DEFAULT_CSS,
    };
  },
});
